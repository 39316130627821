// Types
export type RevenueCatConfig = {
  apiKey: string;
  entitlementId: string;
  baseUri: string;
}

// Global Variables

export const environment = process.env.NODE_ENV;

export const baseUri = process.env.NEXT_PUBLIC_MINDFEED_BASE_URI;

export const growthbook = {
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST || '',
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY || '',
};

export const revenueCat: RevenueCatConfig = {
  apiKey: process.env.NEXT_PUBLIC_REVENUECAT_WEB_API_KEY || '',
  entitlementId: process.env.NEXT_PUBLIC_REVENUECAT_ENTITLEMENT_ID || '',
  baseUri: 'https://api.revenuecat.com/v1'
};

// TODO: Initially env. vars. were directly used in components
// via process.env calls - we should now use this convenient helper