import { useEffect } from "react";
import getFirebase from "services/firebase";
import { closeLoginModal } from "services/ModalService";

// The recommended way to get the current user is by setting an observer on the Auth object
// https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
const useAuthenticationObserver = (): void => {
  useEffect(() => {
    const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(
      user => {
        // TODO: Update Auth Token in State Machine
        if (user) {
          closeLoginModal();
        }
      }
    );
    return () => {
      unregisterAuthObserver();
    };
  }, []);
};

export default useAuthenticationObserver;
