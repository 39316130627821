import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import type { Drop } from "models/Drop";
import { saveDrop } from "services/drop.service";
import { QueryKey } from 'services/queryClient'
import { notify, NotificationType } from 'services/NotificationService'
import { isContext } from "utils/ContextUtils";

type Context = { previousDrops: Drop[] };

export const useSaveDrop = ({
  onSuccess,
}: {
  onSuccess?: (data: Drop) => void;
} = {}): UseMutationResult<Drop, Error, Drop, Context> => {
  const queryClient = useQueryClient();

  const onMutateCallback = async (drop: Drop) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(QueryKey.DROPS);

    // Snapshot the previous value
    const previousDrops = queryClient.getQueryData<Drop[]>(QueryKey.DROPS);

    if (previousDrops) {
      // Whether the drop already exists in the stack
      const itExists = previousDrops.some(d => d.id === drop.id);

      // Optimistically update to the new value
      if (itExists) {
        queryClient.setQueryData<Drop[]>(QueryKey.DROPS,
          previousDrops.map(d => d.id !== drop.id ? d : drop)
        );
      }
      else {
        // We don't do anything because we don't have an ID to use as key
      }
    }

    return { previousDrops };
  };

  const onSuccessCallback = async (drop: Drop, variables, context) => {
    if (context.previousDrops) {
      // Whether the drop already exists in the stack
      const itExists = context?.previousDrops.some(d => d.id === drop.id);

      if (itExists) {
        // Update the recently updated drop
        queryClient.setQueryData<Drop[]>(QueryKey.DROPS,
          context.previousDrops.map(d => d.id !== drop.id ? d : drop)
        );

        // Notify User
        notify(NotificationType.DROP_UPDATED);
      } else {
        // Add the newly created drop
        queryClient.setQueryData(QueryKey.DROPS, (data: Drop[]) => {
          return [...data, drop ];
        });

        // Notify User
        notify(NotificationType.DROP_CREATED);
      }
    }

    if (onSuccess) {
      onSuccess(drop);
    }
  };

  const onErrorCallback = (_err, _variables, context) => {
    // If the mutation fails, use the context returned from onMutate to roll back
    if (isContext<Context>(context, "previousDrops")) {
      queryClient.setQueryData<Drop[]>(
        [QueryKey.DROPS],
        context.previousDrops
      );
    }
  };

  const saveDropMutation = useMutation(
    saveDrop,
    {
      onMutate: onMutateCallback,
      onSuccess: onSuccessCallback,
      onError: onErrorCallback
    }
  );

  return saveDropMutation;
};