import type { Collection } from 'models/Collection';
import { HTTPMethod } from 'utils/HTTPUtils';
import { BASE_URI, authenticatedHeaders } from './api';

export const COLLECTIONS_URI = `${BASE_URI}/api/collections`;

export const getCollections = async (): Promise<Collection[]> => {
  const response = await fetch(COLLECTIONS_URI, {
    method: HTTPMethod.GET,
    headers: await authenticatedHeaders(),
  });
  
  if (!response.ok) {
    throw new Error(`Unable to fetch collections: error ${response.status}`);
  }

  return response.json() as Promise<Collection[]>;
};

export const saveCollection = async ({ id, ...restCollectionProperties }: Collection): Promise<Collection> => {
  const response = await fetch(COLLECTIONS_URI, {
    method: id ? HTTPMethod.PUT : HTTPMethod.POST,
    headers: await authenticatedHeaders(),
    body: JSON.stringify({ id, ...restCollectionProperties }),
  });
  
  if (!response.ok) {
    throw new Error(`Unable to save collection: error ${response.status}`);
  }

  return response.json() as Promise<Collection>;
};

export const deleteCollection = async (id: string): Promise<Collection> => {
  const response = await fetch(`${COLLECTIONS_URI}/${id}`, {
    method: HTTPMethod.DELETE,
    headers: await authenticatedHeaders(),
  });
  
  if (!response.ok) {
    throw new Error(`Unable to delete collection: error ${response.status}`);
  }

  return response.json() as Promise<Collection>;
};
