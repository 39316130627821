import { QueryClient } from 'react-query';

export const queryClient = new QueryClient();

export const clear = (): void => {
  // Clear all connected caches
  queryClient.clear();
}

export enum QueryKey {
  /**
   * The `DROPS` query is used to fetch all drops belonging to the authenticated
   * user.
   */
  DROPS = 'drops',

  /**
   * The `COLLECTIONS` query is used to fetch all collections belonging to the
   * authenticated user.
   */
  COLLECTIONS = 'collections',

  /**
   * The `OPEN_GRAPH_SCRAPER` query is used to fetch open graph data for URLs.
   */
  OPEN_GRAPH_SCRAPER = 'open-graph-scraper',

  /**
   * The `USER_ACCOUNT` query is used to fetch the account belonging to the
   * authenticated user.
   */
  USER_ACCOUNT = 'user-account'
}