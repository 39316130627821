import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  UserCredential,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_CLOUD_IDENTITY_PLATFORM_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_GOOGLE_CLOUD_IDENTITY_PLATFORM_AUTH_DOMAIN,
};

// Firebase instance
let instance: firebase.app.App | null = null;

// Initialize Firebase or get the existing instance
export default function getFirebase(): firebase.app.App | null {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }
  return null;
}

// Providers
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({ display: 'popup' });

/**
 * Authenticate With Google
 *
 * https://firebase.google.com/docs/auth/web/google-signin
 */
export const authenticateWithGoogle = async (): Promise<UserCredential> => {
  const firebaseApp = getFirebase();
  if (!firebaseApp) {
    throw new Error('Firebase is not initialized');
  }
  return signInWithPopup(firebaseApp.auth(), googleProvider);
};

/**
 * Authenticate With Facebook
 *
 * https://firebase.google.com/docs/auth/web/facebook-login
 */
export const authenticateWithFacebook = async (): Promise<UserCredential> => {
  const firebaseApp = getFirebase();
  if (!firebaseApp) {
    throw new Error('Firebase is not initialized');
  }
  return signInWithPopup(firebaseApp.auth(), facebookProvider);
};

/**
 * Authenticate With Apple
 *
 * https://firebase.google.com/docs/auth/web/apple
 */
export const authenticateWithApple = async (): Promise<void> => {
  // TODO: Implement Sign In With Apple
  throw new Error('Sign In With Apple not implemented');
};

/**
 * Authenticate With Email
 *
 * https://firebase.google.com/docs/auth/web/email-link-auth
 */
export const authenticateWithEmail = async (): Promise<void> => {
  // TODO: Implement Sign In With Email
  throw new Error('Sign In With Email not implemented');
};

/**
 * Sign Out
 *
 * https://firebase.google.com/docs/auth/web/google-signin#next_steps
 * https://firebase.google.com/docs/auth/web/facebook-login#next_steps
 * https://firebase.google.com/docs/reference/unity/class/firebase/auth/firebase-auth#class_firebase_1_1_auth_1_1_firebase_auth_1ab53ba445fd7770ee2e9af595258b0231
 */
export const unauthenticate = async (): Promise<void> => {
  const firebaseApp = getFirebase();
  if (!firebaseApp) {
    throw new Error('Firebase is not initialized');
  }
  return signOut(firebaseApp.auth());
};
